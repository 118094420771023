.location_bar_container {
  min-height: auto;
}
.header_container {
  .grow {
    flex-grow: 1;
  }
  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    justify-content: center;
    transition: 0.2s;
    transition-timing-function: linear;

    // background-color: theme.palette.primary.dark,
    // @media only screen and (min-width: 960px) {
    //   flex-direction: column-reverse;
    // }
    &.expanded_mob {
      .on_boarding_bar_container {
        align-items: initial;
        justify-content: initial;
        display: block;
        .mob_expanded_tabs {
          margin-top: 30px;
          margin-bottom: 30px;
          display: block;
          .sub_heading_view {
            top: 85px;
            height: 40px;
          }
          .MuiTabs-flexContainer {
            align-items: center;
            justify-content: center;
            button {
              // margin: 0 25px;
              margin: 0 16px;
              width: 98px;
            }
          }
        }
        .mob_expanded_bar {
          width: 95%;
          margin: auto;
          padding: 20px 16px;
          padding-bottom: 40px;
          overflow-y: auto;
          // height: 100vh;
          display: block;
          background-color: #fff;
          height: auto;
          border-radius: 8px;
          .mob_expanded_tab_panel {
            height: auto;
            .saved_view_container {
              // padding-top: 340px;
              padding-bottom: 0;
              .card_holder {
                @include media_query(S) {
                  padding: 0;
                }
              }
              @include media_query(M) {
                padding-bottom: 120px;
              }
            }
            .sub_heading_view {
              top: 95px;
              height: 28px;
              // justify-content: initial;
              justify-content: space-between;
              .btn_area {
                .trotm_btn {
                  padding: 7px 14px;
                  // padding: 9px 24px;
                }
                .MuiButton-contained.Mui-disabled {
                  color: rgba(255, 255, 255, 0.7);
                  background-color: rgba(0, 67, 109, 0.7);
                }
              }
              .sort_icon_container {
                width: 20px;
                height: 18px;
                cursor: pointer;
                .sort_icon {
                  width: 20px;
                  height: 18px;
                  background-image: url('/icons/icon-sort.svg');
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
              .image_text_container {
                margin-bottom: 16px;
                .square_image_container {
                  background-color: transparent;
                  padding: 0 5px;
                  border: none;
                  .image {
                    border: solid 1px #ffffff;
                    border-radius: 4px;
                    &.active {
                      background-color: #fff;
                    }
                  }
                }
              }
            }
            .save_checkbox {
              margin-bottom: 20px;
              // .MuiCheckbox-root {
              //   color: $white;
              // }
              // .MuiTypography-body1 {
              //   color: $white;
              // }
            }
            .btn_area {
              text-align: center;
              .trotm_btn {
                padding: 9px 24px;
              }
              .MuiButton-contained.Mui-disabled {
                color: rgba(255, 255, 255, 0.7);
                background-color: rgba(0, 67, 109, 0.7);
              }
            }
            .image_text_container {
              // margin-bottom: 16px;
              .square_image_container {
                width: 95%;
                height: 55px;
                background-color: transparent;
                padding: 0 5px;
                margin: 0 auto 8px;
                .image {
                  border: solid 1px $white;
                  border-radius: 4px;
                  &.active {
                    background-color: $white;
                  }
                }
              }
              .text {
                color: rgba(0, 0, 0, 0.87);
              }
            }
          }
          .textfield_area {
            .map_list_container {
              margin-bottom: 30px;
            }
            .MuiInputBase-input {
              background-color: $white;
              border-radius: 8px;
            }
            // Input inside label
            .MuiFormLabel-root {
              // color: $white;
              font-size: 16px;
            }
            .MuiFormControl-root .MuiInputLabel-outlined.MuiInputLabel-shrink {
              background-color: #fff;
              // color: $white;
            }
            // Input selected value color
            .MuiInputBase-root.Mui-disabled {
              // color: $white;
            }
            // Disabled input border
            .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
              // border: 1px solid $white;
              // background-color: $white;
            }
            // Input top label
            .MuiFormLabel-root.Mui-disabled {
              background-color: $white;
              padding-right: 5px;
              color: rgba(0, 0, 0, 0.38);
            }
          }
          @include media_query(S) {
            padding: 20px 16px;
          }
          @media (max-device-width: 823px) and (orientation: landscape) {
            max-height: 100%;
          }
        }
        @media (max-device-width: 823px) and (orientation: landscape) {
          padding-bottom: 120px;
        }
      }
      .header_section {
        &.left_section {
          height: 24px;
          width: 24px;
          top: 16px;
          left: 16px;
          padding: 0;
        }
        &.right_section {
          height: 45px;
        }
      }
    }
  }
  .header_section {
    height: 100%;
    padding: 20px 0;
    &.left_section,
    &.right_section {
      display: flex;
      flex: 2;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;

      position: absolute;

      .icon {
        height: 20px;
        .MuiSvgIcon-root {
          @include media_query(M) {
            width: 20px;
            height: 20px;
          }
        }
        &.salesflash_icon {
          @include media_query(M) {
            // width: 15px;
            height: 15px;
          }
        }
        @include media_query(M) {
          padding: 12px 8px;
        }
      }

      .store_comment_wrapper {
        margin: 0px 12px;
        .add_photo_field {
          margin-bottom: 0px;
          .add_photo_input {
            .upload_button_container {
              &.store_comment {
                display: flex;
                align-items: center;
                border-radius: 8px;
                background-color: rgba(255, 255, 255, 0.1);
                padding: 6px 15px;
                .icon {
                  margin-right: 8px;
                  &.store_comment {
                    width: 22px;
                    height: 20px;
                    background-image: url('/icons/header-icons/icon-store-comment-btn.svg');
                  }
                }
                label {
                  font-size: 12px;
                  color: #fff;
                  font-weight: normal;
                  margin: 0px;
                  @include media_query(M) {
                    display: none;
                  }
                }
                @include media_query(M) {
                  background: transparent;
                  padding: 6px 0px;
                }
              }
            }
          }
        }
        @include media_query(M) {
          margin: 0px;
        }
      }
      @include media_query(M) {
        padding: 10px 0;
      }

      .notification {
        .MuiBadge-colorError {
          color: $white;
        }
      }
    }
    &.right_section {
      right: 24px;
      height: 60px;
      top: 0;
      .icon {
        // height: 20px;
        height: 100%;
        &:last-child {
          padding-right: 0px;
        }
        @include media_query(M) {
          height: 20px;
        }
      }
      @include media_query(M) {
        right: 16px;
      }
    }
    &.left_section {
      left: 0px;
      .icon {
        height: 20px;
        // &:first-child {
        //   padding-left: 0px;
        // }
      }
      @include media_query(M) {
        left: 8px;
      }
    }
    &.on_boarding_bar_container {
      display: flex;
      // flex: 8;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      &.saved_view_section {
        justify-content: initial;

        width: 100%;
        .on_boarding_bar {
          width: 100%;
        }
        .mob_expanded_bar {
          background-color: transparent;
          width: 100%;
          padding: 0;
          height: 100%;
        }
        .my_view_tabs {
          margin-bottom: 60px;
        }
        .MuiBox-root {
          padding: 0;
        }
      }
    }
  }

  .locationg_group_mobile_view {
    color: #616161;
    text-align: center;
    .error_state_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  // Top Tab View
  .my_view_tabs {
    min-height: 27px !important;

    height: 27px;

    transition: 0.5s;
    transition-timing-function: linear;
    overflow: visible;
    button {
      text-transform: capitalize;
      padding: 0;
      min-height: auto;
      min-width: auto;
      margin: 0 40px;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.15px;
      overflow: visible;
      &.Mui-selected {
        font-weight: 500;
      }
      .MuiBadge-anchorOriginTopLeftRectangle {
        top: 6px;
        left: -5px;
      }
      .MuiBadge-dot {
        height: 6px;
        width: 6px;
        min-width: 6px;
      }
    }
    .MuiTabs-indicator {
      display: flex;
      justify-content: center;
      background-color: transparent;
      & > span {
        max-width: 26px;
        width: 100%;
        background-color: $white;
      }
    }
    // After Scroll
    &.hide_tabs {
      // height: 0;
      display: none;
    }
    .MuiTabs-scroller.MuiTabs-fixed {
      overflow: visible !important;
    }
  }

  .on_boarding_bar {
    height: 100%;

    .autocomplete_section {
      .MuiInputBase-input {
        cursor: pointer !important;
      }
      .MuiInputBase-input.Mui-disabled {
        cursor: not-allowed !important;
      }
      // For Cross Icon on Myview
      // .MuiAutocomplete-endAdornment {
      //   background-color: #eeeeee;
      //   .MuiAutocomplete-clearIndicator {
      //     margin-top: 3px;
      //     margin-right: 5px;
      //     width: 15px;
      //     height: 15px;
      //     background-size: contain;
      //     background-image: url('/icons/icon-clear-all.svg');
      //     .MuiIconButton-label {
      //       display: none;
      //     }
      //   }
      //   .MuiAutocomplete-popupIndicator {
      //     display: none;
      //   }
      // }
    }

    .input_field {
      .MuiInputBase-input {
        cursor: pointer !important;
      }
      &.block_input {
        .MuiInputBase-input {
          cursor: not-allowed !important;
        }
      }
    }
  }

  .add_buttons {
    position: absolute;
    bottom: -26px;
    right: 0;
    left: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    // width: 912px;

    margin: 0 auto;
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 240px;
      margin: 0 32px;
      border-radius: 50px;
      height: 56px;
      text-transform: capitalize;
      color: $white;

      transition: all 0.5s;
      transition-timing-function: ease-in-out;

      &.add_store_visit_btn {
        background-color: #81cabb;
      }
      &.add_repair_request_btn {
        background-color: #e06f6b;
      }
      &.add_store_comment_btn {
        background-color: #246094;
      }
      .expand_icon {
        height: 15px;
        width: 15px;
        border-radius: 100%;
        background-color: inherit;
        position: absolute;
        right: 0px;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        .MuiSvgIcon-root {
          height: 15px;
          width: 15px;
        }
      }
    }

    .header_btn_sm {
      padding: 0;

      width: 84px;
      transition: all 0.5s;
      transition-timing-function: ease-in-out;

      .MuiButton-startIcon {
        margin: 0;
      }
      @include media_query(M) {
        width: 55px;
        height: 55px;
        min-width: 55px;
      }
    }
  }
  .my_view_section {
    overflow: hidden;

    display: flex;
    align-items: center;
    background-color: #eeeeee;
    border-radius: 50px;

    transition: all 0.5s;
    transition-timing-function: ease-in-out;

    .divider {
      margin: 15px 0px;
    }
    .section {
      padding: 0 16px;
      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      &:first-child {
        padding-left: 30px;
      }
      &:last-child {
        padding-right: 8px;
      }
      .lbl {
        font-size: 12px;
        color: #212121;
        font-weight: bold;
      }
      .form_control {
        font-size: 12px;
        color: #9e9e9e;

        .MuiInputBase-root {
          padding-right: 0;
          .MuiInputBase-input {
            padding: 2px 0px;
            padding-right: 50px;
            position: relative;
          }
          .MuiAutocomplete-endAdornment {
            top: auto;
            .MuiIconButton-root {
              .MuiSvgIcon-root {
                width: 18px;
                height: 18px;
              }
            }
          }
        }

        // Overrided Padding
        .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
          padding: 2px 0px;
        }
      }
      &.active {
        background-color: $white;
        border-radius: 50px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      .save_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        cursor: pointer;
        padding-right: 16px;
        .icon {
          width: 20px;
          height: 20px;
        }
        .lbl {
          font-size: 12px;
          color: #9e9e9e;
        }
      }
      .go_to_view_btn {
        min-width: 40px;
        border-radius: 50px;
        background-color: $primary_dark;
        padding: 8px;

        &.Mui-disabled {
          background-color: #d8d8dc;
        }
      }
      .MuiInput-underline:before,
      .MuiInput-underline:after {
        border: 0 !important;
      }

      .edit_saved_view_button {
        min-width: 40px;
        max-width: 40px;

        min-height: 40px;
        max-height: 40px;

        border-radius: 20px;
        background-color: $primary_dark;
        color: $white;
        padding: 8px;

        &.Mui-disabled {
          background-color: #d8d8dc;
          cursor: not-allowed !important;
          color: $brownish_gray;
        }
      }

      .edit_saved_view_icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .my_view_section_lg {
    // width: 680px;
    min-width: 135px;
    height: 55px;
    // Temp
    color: #212121;
  }

  // After Scroll
  .my_view_section_sm {
    width: 360px;
    height: 36px;
    border-radius: 50px;
    background-color: $white;
    .header_text_wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0px 16px;
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        //temp
        // border-radius: 50%;
        // background: #eeeeee;
        &.airport_icon {
          background-image: url('/icons/icon-airport.svg');
          background-position: center;
        }
      }
      .text {
        width: 90%;
        color: $primary_main;
        font-size: 12px;
      }
    }

    .small_text_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .left_text,
      .right_text {
        display: inline-block;
        color: $primary_main;
        font-size: 12px;
      }

      .left_text {
        width: 65%;
      }

      .right_text {
        width: 35%;
      }

      .divider {
        margin: 0px 10px;
      }
    }
    // @media only screen and (max-width: 960px) {
    //   position: relative;
    //   top: 45px;
    // }
  }
}

.header_btn_text {
  overflow: hidden;
  white-space: nowrap;
  @include media_query(M) {
    display: none;
  }
}

// Generated Main Dropdown class for list
// !important used because style attribute is added
.MuiAutocomplete-popper {
  top: 15px !important;
  left: 15px !important;
  width: 330px !important;
  .MuiAutocomplete-listbox {
    max-height: 32vh;
  }
  .MuiAutocomplete-option {
    padding: 15px;
    font-size: 12px;
    border-bottom: 1px solid rgba(33, 33, 33, 0.08);
    &:last-child {
      border: none;
    }
  }

  .action_btn_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    .btn_text {
      font-size: 12px;
      color: #00436d;
      cursor: pointer;
    }
  }

  .hide_placeholder {
    background-color: red;
  }

  .brand_container {
    width: 405px;
    min-height: 230px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffff;
    .MuiAutocomplete-listbox {
      padding: 15px;
      .MuiAutocomplete-option {
        border-bottom: 0;
        display: inline-flex;
        // width: 25%;
        width: 33.3%;
        padding: 12px;
        .image_text_container {
          .circle_image_container {
            border: 1px solid #ccc;
            width: 75px;
            height: 75px;
          }
          .label {
            font-size: 12px;
            font-weight: 300;
            font-stretch: normal;
            color: #212121;
            line-height: 1.33;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .chips_wrapper {
      padding: 5px 15px;
      max-height: 100px;
      overflow-y: auto;
      &.my_view {
        max-height: 70px;
      }
    }
    .MuiChip-root {
      height: auto;
      padding: 3px 0px;
    }
    .MuiChip-outlined {
      background-color: #00436d;
      color: #fff;
      font-size: 10px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
    .MuiChip-deleteIconOutlinedColorPrimary {
      height: 16px;
      width: 16px;
      color: #fff;
      &:hover,
      &:active {
        color: #fff;
      }
    }
  }
}
.locations_list_by_group {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 15px;
}
.my_view_region_paper {
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffff;

  .MuiAutocomplete-listbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.brand_paper_controls {
  display: flex;

  .brand_paper_btn {
    margin: 0px 5px;
  }
}
// Dropdown
.autocomplete_section {
  overflow: hidden;
  font-size: 14px;
  width: 115px;
  .form_control {
    width: 100%;
    input {
      font-size: 12px;
      color: #9e9e9e;
    }
  }

  &.autocomplete_multiple {
    overflow: visible;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }
}

.autocomplete_multiple_selection {
  font-size: 10px;
}

.location_group_paper_error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  .title {
    font-size: 18px;
    color: #222;
    font-weight: 400;
    padding: 10px 0px;
  }

  .details {
    font-size: 14px;
    color: #65747c;
    font-weight: 400;
    text-align: center;
  }
}

.header_lg,
.header_sm {
  position: fixed;
  left: 0;
  right: 0;
  transition: 0.5s;
  transition-timing-function: linear;
  z-index: 1201;
}
.header_sm {
  height: 120px;
}

.header_lg {
  height: 200px;
}

.header_mini_l2 {
  height: 64px;
  transition: 0.5s;
  transition-timing-function: linear;
  .header_section {
    padding: 15px 0px;
    .my_view_section_sm {
      background-color: transparent;
      .header_text_wrapper {
        .airport_icon {
          background-image: url('/icons/icon-airport-white.svg');
        }
        .text {
          color: $white;
        }
        .small_text_wrapper {
          .left_text,
          .right_text {
            color: $white;
          }

          .divider {
            background-color: rgba(255, 255, 255, 0.7);
          }
        }
      }

      @include media_query(M) {
        margin-top: 25px;
      }
    }
  }
  @include media_query(M) {
    height: 105px;
  }
}

.header_for_saved_view,
.expanded_mob {
  height: 100vh;

  transition: 0.2s;
  transition-timing-function: linear;
}

.saved_view_container {
  display: block;
  // margin: 0 -12px;
  padding-top: 12px;
  padding-bottom: 60px;
  // width: 100vw;
  // padding: 0 18px;
  // padding-top: 60px;
  // max-height: calc(100vh - 70px);
  // overflow-y: auto;
  .saved_view_cards_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    // position: fixed;
    // left: 24px;
    // right: 24px;
    // width: calc(100% - 48px);
    // margin-top: 12px;

    overflow-y: auto;
  }

  .card_holder {
    padding: 0 12px;
    margin-bottom: 24px;
  }
}
.sub_heading_view {
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-end;

  // margin: 24px 0;
  position: fixed;
  top: 60px;
  height: 60px;
  width: 100%;
  background-color: transparent;
  z-index: 1204;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 0;
  right: 0;
  padding: 0 30px;
  .btn_area {
    margin-right: 5px;
  }
}
.saved_view_tab_panel {
  overflow-y: auto;
  height: 100%;
}

.sortable_card_holder {
  z-index: 1203 !important;
}

.my_view_region_option {
  width: 45%;
  margin: 5px;

  .square_image_container {
    width: 50px;
    height: 50px;

    .image {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
}

.popover {
  border-radius: 8px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.25);

  .inner_wrapper {
    .popup_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      padding: 0 24px;
      position: fixed;
      width: 350px;
      z-index: 1;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #fff;
      border-bottom: 1px solid rgba(33, 33, 33, 0.08);
      .title {
        font-size: 18px;
        font-weight: 500;
      }
      .clr_txt {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: $primary_main;
      }
      @include media_query(S) {
        width: 343px;
      }
    }
    .details {
      padding-top: 60px;
      ul {
        li {
          position: relative;
          padding: 16px 24px;
          padding-bottom: 0;
          cursor: pointer;
          .title {
            display: flex;
            align-items: center;
            height: 24px;
            font-size: 16px;
            margin-bottom: 10px;
            .icon {
              width: 24px;
              height: 24px;
              margin-right: 10px;
              &.store_visit {
                background-image: url('/icons/icon-store-visit-active.svg');
              }
              &.repair_request {
                background-image: url('/icons/icon-repair-request-active.svg');
              }
              &.store_comment {
                background-image: url('/icons/icon-store-comment-active.svg');
              }
            }
            .lbl {
            }
          }
          .desc {
            margin-bottom: 9px;
            font-size: 12px;
          }
          .date_details {
            border-bottom: 1px solid rgba(33, 33, 33, 0.08);
            padding-bottom: 16px;
            font-size: 12px;
            font-weight: 300;
          }
          .delete_icon_section {
            position: absolute;
            right: 24px;
            top: 16px;
            cursor: pointer;
            .delete_icon {
              width: 18px;
              height: 18px;
              background-image: url('/icons/icon-delete.svg');
            }
          }
          &:hover {
            background-color: #f6f6fa;
          }
        }
      }
    }
  }
  &.notification_popup {
    .MuiPopover-paper {
      width: 350px;
      height: 430px;
    }
    .lbl {
      display: flex;
      align-items: center;
      .notification_dot {
        margin-left: 10px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
      }
    }
    .details {
      ul {
        li {
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
  &.recent_activity_section {
    background-color: $white;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.08);

    .inner_wrapper {
      .popup_header {
        position: static;
        width: 100%;
      }
      .details {
        padding-top: 0;
        max-height: 512px;
        min-height: 170px;
        overflow-y: auto;
        position: relative;
        @include media_query(M) {
          max-height: 240px;
        }
      }
    }
    @include media_query(M) {
      display: none;
    }
  }
  &.quick_action_popup {
    .MuiPopover-paper {
      width: 215px;
    }
    .inner_wrapper {
      .details {
        padding-top: 0;
        ul {
          li {
            padding: 16px;
            border-bottom: 1px solid rgba(33, 33, 33, 0.08);
            .title {
              // font-weight: 500;
              color: #00436d;
              margin-bottom: 0px;
              .lbl {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  &.calendar_filter {
    position: absolute;
    background-color: #fff;
    z-index: 3;
    max-height: 280px;
    overflow-y: auto;
    width: 215px;
    .MuiPopover-paper {
      width: 215px;
      max-height: 285px;
    }
    .inner_wrapper {
      .details {
        padding-top: 0;
        width: 100%;
        ul {
          li {
            padding: 16px;
            border-bottom: 1px solid rgba(33, 33, 33, 0.08);
            .title {
              display: flex;
              align-items: center;
              font-size: 16px;
              color: #000000;
              margin-bottom: 0px;
              .primary_text {
                font-weight: 500;
                margin-right: 6px;
              }
              .secondary_text {
                font-weight: normal;
                @include media_query(M) {
                  font-size: 14px;
                }
              }
              &.active {
                font-size: 18px;
                color: #00436d;
                .primary_text {
                  font-size: 20px;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}
